import React, { useEffect, useState } from "react";
import { Swiper, Avatar, PullToRefresh, SideBar } from "antd-mobile";
import { Helmet } from "react-helmet";
import "./index.less";
import ajax from "ajax";
import ImageBox from "@/components/image-box/index";

import "xgplayer";
import hlsjsPlayer from 'xgplayer-hls.js'
import XgVideo from "@/components/xg-video";

import classNames from "classNames";
import { getAssestUrl, getImageUrl } from "@/utils";
import { getSvg } from "@/utils/index";
import { useNavigate } from "react-router-dom";
import ImageIcon from "@/components/image-icon";
import InfiniteScroll from "@/components/infinite-scroll";
import { Tabs, JumboTabs } from "antd-mobile";
import {Image } from 'antd-mobile' 
import { set } from "lodash";

function Index() {

  const navigate = useNavigate();

  const [currentPageNum, setCurrentPageNum] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [bannerList, setBannerList] = useState<any[]>([]);

  const onRefresh = async () => {
    await loadMore();
  };

  const loadMore = async () => {
    const pageNum = currentPageNum + 1;
    const { data } = await ajax.get("/video/getPage", {
      params: {
        type: 'yzt',
        // region:region,
        pageNum,
        pageSize: 10,
      },
    });
    setCurrentPageNum(data.pageNum);
    setBannerList((val) => [...val, ...(data.rows as any[])]);
    setHasMore(data.pageTotal > pageNum);
  };

  


  

  const startPlayer = (id: string) => {
    // alert(id)
    navigate(`/xg-video/${id}`);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>展厅展览</title>
      </Helmet>
      <div className="characteristic-city-page">
                    <PullToRefresh onRefresh={onRefresh}>
                    {/* <div className="characteristic-city-page-banner"> */}
                      <div className="characteristic-city-page-video-list">
                        {bannerList.map(
                          ({ id,onlineFilePath,onlineImgPath,onlineName,thumbnailPath }, index) => {
                            return (
                              <div
                                key={index}
                                className="characteristic-city-page-video-item"
                              >
                                <div className="characteristic-city-page-video-project">
                                  <div className="characteristic-city-page-video-item-item-cover">
                                    {/* <XgVideo
                                      url={onlineFilePath!=""&&onlineFilePath!=null?onlineFilePath:filePath}
                                      poster={onlineImgPath!=""&&onlineImgPath!=null?onlineImgPath:thumbnailPath}
                                    /> */}
                                    <ImageBox
                                      src={thumbnailPath!=null&&thumbnailPath!=""?thumbnailPath:getImageUrl("empty","png")}
                                      fit="cover"
                                      onClick={() => startPlayer(id)}
                                      // style={{ width: "100%", height: "100%" }}
                                    />
                                  </div>

                                  <div className="characteristic-city-page-video-item-item-title">
                                    {onlineName || "宣传片"}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                        <InfiniteScroll
                            loadMore={() => loadMore()}
                            hasMore={hasMore}
                            hasData={!!bannerList.length}
                          />
                      </div>
                    {/* </div> */}
                  </PullToRefresh>
        
      </div>
    </>
  );
}
export default React.memo(Index);

import { RouteObject, useRoutes } from 'react-router-dom'
import App from '@/App'
import Home from '@/pages/home'
import IndexPage from '@/pages/Index/index'
import ProjectExhibition from '@/pages/project-exhibition'
import ProjectDtfb from '@/pages/project-dtfb'
import ArticleDetail from '@/pages/article-detail'
import ArticleDetailFwzn from '@/pages/article-detail-fwzn'
import LiveOnline from '@/pages/live-online'
import LiveOnlineDetail from '@/pages/live-online-detail'
import LiveImagePage from '@/pages/live-image'
import CharacteristicCity from '@/pages/characteristic-city'
import YztSp from '@/pages/yzt-sp'
import YztXcfc from '@/pages/live-image-x'
import CommentPage from '@/pages/live-online/comment-page'
import CivilizedPractice from '@/pages/civilized-practice'
import HdlcIndexPage from '@/pages/civilized-practice/hdlc'
import CivilizedPracticeDetail from '@/pages/civilized-practice/details'
import TypicalStyle from '@/pages/typical-style'
import LoadingPage from '@/components/loading-page'
import vgVideoPage from '@/components/xg-video'

import { Avatar } from 'antd-mobile'
import { getSvg, getImageUrl } from '@/utils'
import XgVideo from '@/components/xg-video'
import XgVideo5 from '@/components/xg-video5'
import XgVideo6 from '@/components/xg-video6'

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'index',
        element: <IndexPage />,
        index: true,
      },
      {
        path: 'home',
        element: <LoadingPage Component={Home} />,
      },
      {
        path: 'project-exhibition',
        // element: <LoadingPage Component={ProjectExhibition} url='xmzt1'/>,
        element: <ProjectExhibition />
      },
      // {
      //   path: 'project-jishi',
      //   // element: <LoadingPage Component={ProjectExhibition} url='xmzt1'/>,
      //   element: <ProjectXmjs />
      // },
      {
        // 动态信息
        path: 'project-dtfb',
        // element: <LoadingPage Component={ProjectExhibition} url='xmzt1'/>,
        element: <ProjectDtfb />
      },
      {
        path: 'article-detail/:id',
        element: <ArticleDetail />
      },
      {
        path: 'article-detail-fwzn/:id',
        element: <ArticleDetailFwzn />
      },
      {
        path: 'typical-style',
        // element: <LoadingPage Component={TypicalStyle} url='dxfc1'/>
        element:<TypicalStyle />
      },
      
      {
        path: 'live',
        // element: <LoadingPage Component={LiveOnline} url='zxgs1'/>
        element: <LiveOnline />
      },
      {
        path: 'live-detail/:id',
        element: <LiveOnlineDetail />
      },
      {
        path: 'live-image',
        element: <LiveImagePage />
      },
      {
        path:'follow-report/:id',
        element: <CommentPage />
      },
      {
        path: 'characteristic-city',
        // element: <LoadingPage Component={CharacteristicCity} url='zyjh1'/>,
        element: <CharacteristicCity />
      },
      {
        path: 'yzt-sp',
        // element: <LoadingPage Component={CharacteristicCity} url='zyjh1'/>,
        element: <YztSp />
      },
      {
        path: 'yzt-xcfc',
        // element: <LoadingPage Component={CharacteristicCity} url='zyjh1'/>,
        element: <YztXcfc />
      },
      {
        path: 'civilized-practice',
        // element: <LoadingPage Component={CivilizedPractice} url='fwzn1'/>
        element: <CivilizedPractice />
      },
      {
        path: 'civilized-practice/:type',
        element: <CivilizedPracticeDetail />
      },
      {
        path: 'hdlcIndex',
        element: <HdlcIndexPage />
      },
      {
        path: 'xg-video/:id',
        element: <XgVideo />
      },
      {
        path: 'xg-video5/:id',
        element: <XgVideo5 />
      },
      {
        path: 'xg-video6/:id',
        element: <XgVideo6 />
      },
    ],
  },
]
export const tabs = [
  {
    key: 'typical-style',
    title: '云展厅',
    icon: (active: boolean) => (active ? <Avatar src={getImageUrl('tabbar2_active')}/> : <Avatar src={getImageUrl('tabbar2')}/>),
  },
  {
    key: 'project-dtfb',
    title: '动态发布',
    icon: (active: boolean) => (active ? <Avatar src={getImageUrl('tabber1_active')}/> : <Avatar src={getImageUrl('tabbar1')}/>),
  },
  // {
  //   key: 'project-jishi',
  //   title: '项目集市',
  //   icon: (active: boolean) => (active ? <Avatar src={getImageUrl('tabber1_active')}/> : <Avatar src={getImageUrl('tabbar1')}/>),
  // },
  {
    key: 'live',
    title: '线上直播',
    icon: () => (<Avatar className='tab3' src={getImageUrl('tabbar3')}/>),
  },
  {
    key: 'characteristic-city',
    title: '志愿江海',
    icon: (active: boolean) => (active ? <Avatar src={getImageUrl('tabbar4_active')}/> : <Avatar src={getImageUrl('tabbar4')}/>),
  },
  {
    key: 'civilized-practice',
    title: '服务指南',
    icon: (active: boolean) => (active ? <Avatar src={getImageUrl('tabbar5_active')}/> : <Avatar src={getImageUrl('tabbar5')}/>),
  },
]


export default function Index() {
  const ele = useRoutes(routes)
  return ele
}

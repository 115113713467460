import { Image } from 'antd-mobile'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import store from './index.store'
import { For } from 'tsx-control-statements/components'
import './index.less'
import { useNavigate, Outlet, useParams } from 'react-router-dom'
import { ArticleProps } from 'types'
import { useLocation } from 'react-router'
import { getImageUrl } from '@/utils'

function Index() {
  const { type = '' } = useParams()
  useEffect(() => {
    store.init(type)
    return () => {
      store.clear()
    }

  }, [])
  const getUseNavigate = useNavigate()
  const location = useLocation()
  if (location.pathname == '/civilized-practice/contactInformation') {
    var listMap = store.list.map(item => item.id)
    getUseNavigate('/article-detail-fwzn/' + listMap[0])
  }
  return (
    <div className='cp-detai'>
      <For
        each="one"
        of={store.list}
        body={(item, index) => <ListCard key={item.id} item={item} />}
      />
    </div>
  )
}
export default observer(Index)

interface CardProps {
  item: ArticleProps
}
const ListCard = observer(({ item }: CardProps) => {
  const navigate = useNavigate()
  const onclic = (id: number) => {
    navigate(`/article-detail-fwzn/${id}`)
  }
  return (
    <div className='card' onClick={() => onclic(item.id)}>
      <div className="title">{item.title}</div>
      <Image className='img' width={160} height={100} src={item.coverFilePath==null||item.coverFilePath==""?getImageUrl("empty","png"):item.coverFilePath} fit='cover' />
      <Outlet />
    </div>
  )
}
)
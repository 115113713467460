import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './index.less'
import xgPlayer from "xgplayer";
import hlsjsPlayer from 'xgplayer-hls.js'
import ajax from "ajax";

interface Props {
  url: string,
  poster: string
}

let uuid = 0;

// function Video({ url, poster }: Props) {
function Video() {

  const [videoUrl, setVideoUrl] = useState("");
  const [videoPoster, setVideoPoster] = useState("");
  const [videoId] = useState(uuid++);
  const { id = ''} = useParams();

  const [play5videos] = useState([
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/0.png',
      value: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/站长说1许艾芳/站长说1许艾芳.mp4',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/1.png',
      value: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/站长说2黄海/站长说2黄海.mp4',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/2.png',
      value: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/站长说3李汉田/站长说3李汉田.mp4',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/3.png',
      value: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/站长说4李亚东/站长说4李亚东.mp4',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/4.png',
      value: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/站长说5赵斌/站长说5赵斌.mp4',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/5.png',
      value: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/站长说6雷苏湘/站长说6雷苏湘.mp4',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/6.png',
      value: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/站长说7张来涛/站长说7张来涛.mp4',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/7.png',
      value: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/站长说8刘兆利/站长说8.mp4',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/8.png',
      value: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/站长说9王红美/站长说9.mp4',
    }
  ]);

  const startPlayer = (url: string,poster: string) => {
    
    const player = new xgPlayer({
      id: `xg-video-live-${videoId}`,
      url:url,
      poster:poster,
      width: '100%',
      height: '100vh',
      cssFullscreen: true,
      // controls: false,
      loop: true,
      videoInit: true,
      'x5-video-player-type':'h5',
      playsinline: true,
      lang:"zh-cn",
      autoplay: true,
      // videoInit: true,
      // cssFullscreen: true,
      // fluid: true
    })
    // player.getFullscreen(player.root);
    

    // player.getFullscreen(player.root);
    // player.play()  
    return () => {
      player.destroy();
      // alert("xxx")
    }
  }


  useEffect(() => {
    
      setVideoUrl(play5videos[parseInt(id)].value)
      setVideoPoster(play5videos[parseInt(id)].label)
    
    startPlayer(videoUrl,videoPoster)
  });

  

  return (
    <div className="xg-video" id={`xg-video-live-${videoId}`}>
    </div>
  )
}
export default observer(Video)

import { Avatar } from "antd-mobile";
import { Helmet } from "react-helmet";
import ajax from "ajax";
import "./index.less";
import { useEffect, useState } from "react";
import { InfiniteScroll, ImageViewer, PullToRefresh } from "antd-mobile";
import { useParams } from "react-router-dom";
// import XgVideo from '@/components/xg-video/index'
import ImageBox from "@/components/image-box";
import ImageIcon from "@/components/image-icon";
import StarBox from "@/components/star-box";
import { useNavigate } from "react-router-dom";
import xgPlayer from "xgplayer";
// import hlsjsPlayer from 'xgplayer-hls.js'
import { getAssestUrl, getImageUrl } from "@/utils";

const LiveOnlineDetailPage = () => {
  const navigate = useNavigate();

  const { id = '' } = useParams()
  const [dzs,setDzs] = useState(0)

  const [liveDetail, setLiveDetail] = useState({
    id: '',
    nickName: '',
    clickQuantity: 0,
    thumbsUpQuantity: 0,
    liveUrl: '',
    liveState: 0,
    livePreviewImageId: '',
    livePreviewImagePath: '',
    showPreviewImageId: '',
    showPreviewImagePath: '',
    numOfComments: 0,
    avatar: '',
    deptName: '',
    createTime: '',
    title: '',
    description: '',
    sort:0
  })

  const [play5videos] = useState([
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/0.png',
      value: '0',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/1.png',
      value: '1',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/2.png',
      value: '2',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/3.png',
      value: '3',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/4.png',
      value: '4',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/5.png',
      value: '5',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/6.png',
      value: '6',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/6.png',
      value: '7',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/8.png',
      value: '8',
    }
  ]);
 

  const [play6videos] = useState([
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/0.png',
      value: '0',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/1.png',
      value: '1',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/2.png',
      value: '2',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/3.png',
      value: '3',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/4.png',
      value: '4',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/5.png',
      value: '5',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/6.png',
      value: '6',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/7.png',
      value: '7',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play5/pic/8.png',
      value: '8',
    }
  ]);

  useEffect(() => {
    
    ajax.get(`/live/${id}`).then((res) => {
      setLiveDetail(res.data);
      setDzs(res.data.thumbsUpQuantity)
      startPlayer(res.data.liveUrl,res.data.livePreviewImagePath)
    })
    
  }, [])

  const [currentPageNum, setCurrentPageNum] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [listData, setListData] = useState<any[]>([]);

  const loadMore = async (pageNum = currentPageNum + 1) => {
    const { data } = await ajax.get("/wonderfulComments/list", {
      params: {
        liveId: id,
        pageNum,
        pageSize: 10,
      },
    });
    setCurrentPageNum(data.pageNum);
    if (pageNum === 1) {
      setListData(data.rows);
    } else {
      setListData((val) => [...val, ...(data.rows as any[])]);
    }
    setHasMore(data.pageTotal > pageNum);
  };


  const onRefresh = async () => {
    await loadMore(1);
  }

  const previeImage = (list: any[], defaultIndex: number) => {
    ImageViewer.Multi.show({
      defaultIndex,
      images: list.map((item) => item.filePath),
    });
  };


  const thumbsPlusOne = async () => {
    const res = await ajax.post('/live/thumbsPlusOne', {
      id,
    })
    setDzs(dzs+1)
    
    // alert(liveDetail.thumbsUpQuantity)
    // setLiveDetail((data) => ({ ...data, thumbsUpQuantity: res.data.thumbsUpQuantity }))
  }

  const startPlayer = (url: string,poster: string) => {
    
    const player = new xgPlayer({
      id: `videoBox`,
      url:url,
      poster:poster,
      width: '100%',
      height: '30vh',
      cssFullscreen: true,
      controls: false,
      loop: true,
      videoInit: true,
      'x5-video-player-type':'h5',
      playsinline: true,
      lang:"zh-cn",
      autoplay: true,
      // videoInit: true,
      // cssFullscreen: true,
      // fluid: true
    })
    // player.getFullscreen(player.root);
    

    // player.getFullscreen(player.root);
    // player.play()  
    return () => {
      player.destroy();
      // alert("xxx")
    }
  }

    // const player = new xgPlayer({
    //   id: "videoBox",
    //   // url: "//sf1-cdn-tos.huoshanstatic.com/obj/media-fe/xgplayer_doc_video/hls/xgplayer-demo.m3u8",
    //   // url: "http://vol-nt-admin-01.huigu.com/fvod/Volu/ObHixXhnR/video.m3u8",
    //   url:liveDetail.liveUrl,
    //   // url: getAssestUrl("videos/cover.mp4"),
    //   width: "100%",
    //   height: "30vh",
    //   cssFullscreen: true,
    //   videoInit: true,
    //   controls: false,
    //   loop: true,
    //   'x5-video-player-type':'h5',
    //   playsinline: true,
    //   lang:"zh-cn",
    //   poster: liveDetail.livePreviewImagePath,
    //   // isLive: false,
    //   // preloadTime: 30,
    //   // minCachedTime: 5,
    //   // cors: true
    // //   thumbnail: {
    // //     // pic_num: 44,
    // //     width: 160,
    // //     height: 90,
    // //     col: 10,
    // //     row: 10,
    // //     urls: ['./xgplayer-demo-thumbnail-1.jpg','./xgplayer-demo-thumbnail-2.jpg'],
    // //     isShowCoverPreview: false
    // // },
    // });

    const startPlayer5 = (id:string) => {
      // alert(id)
      navigate(`/xg-video5/${id}`);
    };

    const startPlayer6 = (id:string) => {
      // alert(id)
      navigate(`/xg-video6/${id}`);
    };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>直播详情</title>
      </Helmet>
      <div className="live-online-detail-page">
        <PullToRefresh onRefresh={onRefresh}>
        <If condition={liveDetail.sort != 5 && liveDetail.sort != 6 }>
          <div className="top-banner" id="videoBox"></div>
        </If>
        <If condition={liveDetail.sort === 5 }>
          {play5videos.map((item, index) => {
              return (
                <div key={index} className="characteristic-city-page-video-list">
                  {/* {index} */}
                  <ImageBox 
                    src={item.label}
                    fit="cover"
                    onClick={() => startPlayer5(item.value)}
                    // style={{ width: "100%", height: "100%" }}
                  />
                </div>
              );
            })}
        </If>
        <If condition={liveDetail.sort === 6 }>
          {play6videos.map((item, index) => {
              return (
                <div key={index} className="characteristic-city-page-video-list">
                  {/* {index} */}
                  <ImageBox 
                    src={item.label}
                    fit="cover"
                    onClick={() => startPlayer6(item.value)}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              );
            })}
        </If>
          {/* {
            liveDetail.id && <XgVideo
              url={liveDetail.liveUrl}
              poster={liveDetail.livePreviewImagePath}
            />
          } */}
          <div className="live-online-detail-page-desc">
            <div className="live-online-detail-page-title">
              {liveDetail.title}
            </div>
            <div className="live-online-detail-page-desc-title">
              {liveDetail.description}
            </div>
          </div>

          <div className="live-online-detail-page-account">
            <Avatar src={liveDetail.avatar} />
            <div className="live-online-detail-page-account-content">
              <div className="live-online-detail-page-account-header">
                <div className="live-online-detail-page-account-nickname">
                  {/* {liveDetail.nickName} */}
                </div>
                <div className="live-online-detail-page-account-operation">
                  <StarBox onClick={thumbsPlusOne} content={
                    data => (
                      <div {...data}>
                        <ImageIcon name='star' />
                        {dzs}
                      </div>
                    )
                  } />
                  {/* <div>
                    <ImageIcon
                      name='eyes'
                    />
                    {liveDetail.clickQuantity}
                  </div> */}
                </div>
              </div>
              <div className="live-online-detail-page-account-body">
                来自<span>{liveDetail.deptName}</span>
              </div>
              <div className="live-online-detail-page-account-footer">
                {liveDetail.createTime}
              </div>
            </div>
          </div>
          <div className="live-online-detail-page-container">
            <div className="live-online-detail-page-title">
              跟踪报道
            </div>
            <div className="live-online-detail-page-list">
              {listData.map((item, idx) => {
                return (
                  <div className="live-online-detail-page-item" key={item.id} >
                    <div className="live-online-detail-page-item-header">
                      <Avatar src={liveDetail.avatar} />
                      <div className="live-online-detail-page-item-header-detail">
                        <div className="live-online-detail-page-item-nickname">
                          {liveDetail.nickName}
                        </div>
                        <div className="live-online-detail-page-item-deptname">
                          来自<span>{liveDetail.deptName}</span>
                        </div>
                      </div>
                      <div className="live-online-detail-page-item-time">
                        {item.createTime}
                      </div>
                    </div>
                    <div className="live-online-detail-page-item-body">
                      <div className="live-online-detail-page-item-body-line" />
                      <div className="live-online-detail-page-item-body-content">
                        <div className="live-online-detail-page-item-content">
                          {item.content}
                        </div>
                        <div className="live-online-detail-page-item-image-list">
                          {
                            item.files.map((fielItem: any, idx: number) => (
                              <div className="live-online-detail-page-item-image-wrap" key={fielItem.id} onClick={() => previeImage(item.files, idx)}>
                                <ImageBox fit="cover" src={fielItem.filePath} />
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <InfiniteScroll loadMore={() => loadMore()} hasMore={hasMore} />
        </PullToRefresh>

        <div className="top-banner" id="videoBox3"></div>
      </div>
    </>
  );
};

export default LiveOnlineDetailPage;

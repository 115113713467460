import {
    Image,

} from 'antd-mobile'
import type { ImageProps } from 'antd-mobile/es/components/image'

import React from 'react'

const ImageBox = (props: ImageProps) => {
    return <Image {...props} />
}

export default React.memo(ImageBox)
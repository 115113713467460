import { runInAction } from 'mobx';
import { action } from 'mobx';
import { observable } from 'mobx';
import { makeAutoObservable } from 'mobx';
import ajax from 'ajax'
import {ReportListProps} from '@/types/index'

class store {
  constructor(){
    makeAutoObservable(this,{
      id: observable,
      data:observable,
      getData: action,
      init: action
    })
  }

  id = ''
  data:ReportListProps = {
    id: 0,
    avatar: '',
    liveId: '',
    content: '',
    nickName: '',
    numOfComments: 0,
    clickQuantity: 0,
    thumbsUpQuantity: 0,
    deptName: '',
    createUser: '',
    updateUser: '',
    createTime: '',
    updateTime: '',
    files: []
  }
  init = (id:string) =>{
    this.id = id
    this.getData()

  }
  getData = () =>{
    ajax.get(`/wonderfulComments/${this.id}`).then((res)=>{
      runInAction(()=>{
        this.data = res.data
      })
    })
    

  }

}
export default new store()
import { Helmet } from "react-helmet";
import './index.less'
import { useEffect } from "react";
import { getAssestUrl, getImageUrl } from "@/utils";
import Player from 'xgplayer'
import { useNavigate } from "react-router-dom";

const IndexPage = () => {

  const navigate = useNavigate()
  
  useEffect(() => {
    // const player = new Player({
    //   id: 'videoBox',
    //   url: getAssestUrl('videos/cover.mp4'),
    //   width: '100%',
    //   height: '30vh',
    //   // cssFullscreen: true,
    //   controls: false,
    //   loop: true,
    //   videoInit: true,
    //   'x5-video-player-type':'h5',
    //   playsinline: false,
    //   poster: getImageUrl('test','jpg')
    // })

  //   return () => {
  //     player.destroy();
  //   }

  navigate('/home')

  }, [])

  // const onClick =  () => {
  //   navigate('/home')
  // }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>第七届江苏志愿服务展示交流会</title>
      </Helmet>

      <div className="index-page">
        {/* <div id="videoBox" className="top-banner"></div>
  
        <div className="index-page-cover" onClick={onClick}>
          进入首页
        </div> */}
      </div>
    </>
  )
}

export default IndexPage
import React, { useEffect, useState } from "react";
import { Swiper, Avatar, PullToRefresh, SideBar } from "antd-mobile";
import { Helmet } from "react-helmet";
import "./index.less";
import ajax from "ajax";
import ImageBox from "@/components/image-box/index";

import "xgplayer";
import hlsjsPlayer from 'xgplayer-hls.js'
import XgVideo from "@/components/xg-video";

import classNames from "classNames";
import { getAssestUrl, getImageUrl } from "@/utils";
import { getSvg } from "@/utils/index";
import { useNavigate } from "react-router-dom";
import ImageIcon from "@/components/image-icon";
import InfiniteScroll from "@/components/infinite-scroll";
import { Tabs, JumboTabs } from "antd-mobile";
import {Image } from 'antd-mobile' 
import { set } from "lodash";

function Index() {

  const tabs = [
    {
      label: "精彩视界",
      value: "promotional",
    },
    {
      label: "特色展示",
      value: "vol_jianghai",
    },
  ];

  const navigate = useNavigate();

  const [region, setRegion] = useState("全市");
  const [regionList, setRegionList] = useState<any[]>([]);

  const [dictType, setDictType] = useState(tabs[0].value);
  const [projectTypelist, setProjectTypeList] = useState<
    Record<string, string>[]
  >([]);
  const [projectType, setProjectType] = useState("");
  const [currentPageNum, setCurrentPageNum] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [articleList, setArticleList] = useState<any[]>([]);
  const [bannerList, setBannerList] = useState<any[]>([]);

  

  // const [tab, setTab] = useState(tabs[0].value);

  useEffect(() => {
    // ajax
    //   .get("/video/getPage", {
    //     params: {
    //       pageNum: 1,
    //       pageSize: 10,
    //       type: "promotional",
    //       region:region
    //     },
    //   })
    //   .then((res) => {
    //     setBannerList(res.data.rows);
    //   });

    // ajax
    //   .get("/video/getPage", {
    //     params: {
    //       pageNum: 1,
    //       pageSize: 20,
    //       type: "featured",
    //     },
    //   })
    //   .then((res) => {
    //     setBannerFeaturedList(res.data.rows);
    //   });

    ajax
      .get("/dict/getByDictType", {
        params: {
          dictType: "vol_region",
        },
      })
      .then((res) => {
        setRegionList(res.data);
        setRegion(res.data[0].dictValue);
      });
  }, []);

  const goArticleDetail = (id: string, link: string) => {
    if (link != "" && link != null) {
      window.location.href = link;

      return;
    } else {
      navigate(`/article-detail/${id}`);
    }
  };

  // const getVideoPage = (val: string) => {
  //   ajax
  //     .get("/video/getPage", {
  //       params: {
  //         pageNum: 1,
  //         pageSize: 10,
  //         type: val.toString(),
  //       },
  //     })
  //     .then((res) => {
  //       const pageNum = currentPageNum + 1;
  //       setBannerList(res.data.rows);
  //       setCurrentPageNum(res.data.pageNum);
  //       setHasMore(res.data.pageTotal > pageNum);
  //     });
  // };



  const onRefresh = async () => {
    await loadMore();
  };

  const loadMore = async () => {
    const pageNum = currentPageNum + 1;
    const { data } = await ajax.get("/video/getPage", {
      params: {
        type: 'promotional',
        region:region,
        pageNum,
        pageSize: 10,
      },
    });
    setCurrentPageNum(data.pageNum);
    setBannerList((val) => [...val, ...(data.rows as any[])]);
    setHasMore(data.pageTotal > pageNum);
  };

  const onRefreshTS = async () => {
    await loadMoreTS();
  };

  const loadMoreTS = async () => {
    const pageNum = currentPageNum + 1;
    const { data } = await ajax.get("/regionArticle/list", {
      params: {
        // type: dictType,
        region:region,
        pageNum,
        pageSize: 10,
      },
    });
    setCurrentPageNum(data.pageNum);
    setArticleList((val) => [...val, ...(data.rows as any[])]);
    setHasMore(data.pageTotal > pageNum);
  };

  const onDictTypeChange = (val: string) => {
    // console.info("222",regionList[0].dictValue)
    setDictType(val);
    setRegion(regionList[0].dictValue);
    setBannerList([]);
    setArticleList([]);
    setHasMore(true);
    setCurrentPageNum(0);
  };

  const onRegionChange = (val: string) => {
    console.log(val)
    setRegion(val);
    setArticleList([]);
    setBannerList([]);
    setHasMore(true);
    setCurrentPageNum(0);
  };


  useEffect(() => {
    const player = new hlsjsPlayer({
      id: "videoBox",
      // url: "//sf1-cdn-tos.huoshanstatic.com/obj/media-fe/xgplayer_doc_video/hls/xgplayer-demo.m3u8",
      // url: "http://vol-nt-admin-01.huigu.com/fvod/Volu/ObHixXhnR/video.m3u8",
      url:"http://play.nccia.cn/fvod/Volu/ObHixXhnR/video.m3u8",
      // url: getAssestUrl("videos/cover.mp4"),
      width: "100%",
      height: "30vh",
      cssFullscreen: true,
      videoInit: true,
      controls: false,
      loop: true,
      'x5-video-player-type':'h5',
      playsinline: true,
      lang:"zh-cn",
      poster: getImageUrl("pfdll", "jpg"),
    //   thumbnail: {
    //     // pic_num: 44,
    //     width: 160,
    //     height: 90,
    //     col: 10,
    //     row: 10,
    //     urls: ['./xgplayer-demo-thumbnail-1.jpg','./xgplayer-demo-thumbnail-2.jpg'],
    //     isShowCoverPreview: false
    // },
    });

    return () => {
      player.destroy();
    };
  }, []);

  const startPlayer = (id: string) => {
    // alert(id)
    navigate(`/xg-video/${id}`);
    // XgVideo() 

    // const player = new hlsjsPlayer({
    //   id: `xg_player_img`,
    //   url: filePath,
    //   width: "100%",
    //   height: "100%",
    //   // videoInit: true,
    //   errorTips: `请<span>刷新</span>试试`,
    //   "x5-video-player-type": "h5",
    //   cssFullscreen: true,
    //   videoInit: true,
    //   controls: false,
    //   loop: true,
    //   playsinline: true,
    //   lang:"zh-cn",
    // });
    // player.on("exitCssFullscreen", () => {
    //   player.root.style.height = "";
    //   player.root.style.width = "";
    //   player.destroy();
    // });
    // player.play();
    // player.getFullscreen(player.root);
  };

  // ----------------
  const goViodeDetail = () => {
      window.location.href = 'http://play.nccia.cn/fvod/Volu/ObHixXhnR/video.m3u8';
  }


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>志愿江海</title>
      </Helmet>

      {/* <Image className="top-banner" src={getImageUrl('bg_online_live')} onClick={() => goViodeDetail()} fit='fill'/> */}
{/* <iframe
    allowFullScreen={true}
    height= "500"
    scrolling={'no'}
    src='https://zxrg.jhzyz.gov.cn/video.aspx'
/> */}
      {/* <iframe ref="iframe" scrolling="yes" frameBorder="0"
        style={{ width: '100%', height: '30vh', overflow: 'visible' }}
        onLoad={() => {
          let h = document.documentElement.clientHeight - 20;
          
        } }
        src='https://zxrg.jhzyz.gov.cn/video.aspx' /> */}
      <div></div>  

      <div className="top-banner" id="videoBox"></div>

      <div className="characteristic-city-page">

        {/* <div className="typical-style-page-tabs">
          {tabs.map((item) => {
            return (
              <div
                key={item.value}
                onClick={() => onDictTypeChange(item.value)}
                className={classNames({
                  "typical-style-page-tabs-item": true,
                  active: item.value === dictType,
                })}
              >
                {item.label}
              </div>
            );
          })}
        </div> */}

        <Tabs  activeKey={dictType} onChange={(val) => {onDictTypeChange(val)}}>
          {tabs.map((item) => {
            return (
              <Tabs.Tab key={item.value} title={item.label} className="">
                <If condition={item.value === "promotional"}>
                  <PullToRefresh onRefresh={onRefresh}>
                    <div className="characteristic-city-page-banner">
                      {/* <div className="characteristic-city-page-title">
                        <ImageIcon name="logo" />
                        <span>南通市文明实践志愿服务宣传片</span>
                      </div> */}
                      <JumboTabs
                        activeKey={region}
                        onChange={(val) => {
                          onRegionChange(val);
                          return setRegion(val);
                        }}
                        >
                        {regionList.map((item) => (
                          <JumboTabs.Tab
                            key={item.dictValue}
                            title={null}
                            description={item.dictLabel}
                          />
                        ))}
                      </JumboTabs>
                      <div className="characteristic-city-page-video-list">
                        {bannerList.map(
                          ({ id,onlineFilePath,onlineImgPath,onlineName,thumbnailPath }, index) => {
                            return (
                              <div
                                key={index}
                                className="characteristic-city-page-video-item"
                              >
                                <div className="characteristic-city-page-video-project">
                                  <div className="characteristic-city-page-video-item-item-cover">
                                    {/* <XgVideo
                                      url={onlineFilePath!=""&&onlineFilePath!=null?onlineFilePath:filePath}
                                      poster={onlineImgPath!=""&&onlineImgPath!=null?onlineImgPath:thumbnailPath}
                                    /> */}
                                    <ImageBox
                                      src={thumbnailPath!=null&&thumbnailPath!=""?thumbnailPath:getImageUrl("empty","png")}
                                      fit="cover"
                                      onClick={() => startPlayer(id)}
                                      // style={{ width: "100%", height: "100%" }}
                                    />
                                  </div>

                                  <div className="characteristic-city-page-video-item-item-title">
                                    {onlineName || "宣传片"}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                        <InfiniteScroll
                            loadMore={() => loadMore()}
                            hasMore={hasMore}
                            hasData={!!bannerList.length}
                          />
                      </div>
                    </div>
                  </PullToRefresh>
                </If>
                <If condition={item.value === "vol_jianghai"}>
                  <PullToRefresh onRefresh={onRefreshTS}>
                    <div className="characteristic-city-page-body">
                      {/* <div className="characteristic-city-page-body-header">
                        <div className="characteristic-city-page-title">
                          <ImageIcon name="locaction" />
                          <span>江海志愿服务特色展示</span>
                        </div>
                      </div> */}
                      <div className="characteristic-city-page-container">
                        <JumboTabs
                          activeKey={region}
                          onChange={(val) => {
                            // loadMore();
                            onRegionChange(val);
                            // return setvRegion(val);
                          }}
                          className="characteristic-city-page-container-jumb"
                        >
                          {regionList.map((item) => (
                            <JumboTabs.Tab
                              key={item.dictValue}
                              title={null}
                              description={item.dictLabel}
                              className=""
                            />
                          ))}
                        </JumboTabs>
                        <div className="characteristic-city-page-list">
                          {articleList.map((item) => {
                            return (
                              <div
                                className="characteristic-city-page-item"
                                key={item.id}
                                onClick={() =>
                                  goArticleDetail(item.id, item.link)
                                }
                              >
                                <ImageBox
                                  className="characteristic-city-page-item-cover"
                                  src={item.coverFilePath}
                                  fit="cover"
                                />
                                <div className="characteristic-city-page-item-body">
                                  <div className="characteristic-city-page-item-title">
                                    {item.title}
                                  </div>
                                  <div className="typical-style-page-article-item-footer">
                                    <div className="time">
                                      {item.updateTime
                                        ? item.updateTime
                                        : item.createTime}
                                    </div>
                                    <div className="star">
                                      <ImageBox
                                        src={getImageUrl("smile")}
                                        fit="fill"
                                      />
                                      {item.pageView}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          <InfiniteScroll
                            loadMore={() => loadMoreTS()}
                            hasMore={hasMore}
                            hasData={!!articleList.length}
                          />
                        </div>
                      </div>
                    </div>
                  </PullToRefresh>
                </If>
              </Tabs.Tab>
            );
          })}
        </Tabs>
        <div id="xg_player_img"></div>
      </div>
    </>
  );
}
export default React.memo(Index);

import React, { useState, useEffect } from "react";
import {Image } from 'antd-mobile' 
import { getImageUrl } from '@/utils'
import './index.less'
import classNames from 'classNames';
import { useLocation } from "react-router-dom";

const LoadingPage = ({Component, url = 'loading-bg'}: { Component: React.FC, url?: string }) => {
  
  const location =useLocation()
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(false)
  }, [location.pathname])

  return <>
    <div onTouchEnd={() => setVisible(true)} onMouseUp = {() => setVisible(true)}>
      <Image  className= {classNames({ 'loading-page': true, rotateOutUpLeft: visible })} src={getImageUrl(url,'jpg')}  fit='fill' />
    </div>
    <Component />
  </>
}

export default LoadingPage
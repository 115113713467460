import { useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { SideBar, JumboTabs } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import ImageBox from "@/components/image-box/index";
import ajax from "ajax";
import "./index.less";
import classNames from "classNames";
import { getImageUrl } from '@/utils'
import {Image } from 'antd-mobile' 
import ImageIcon from "@/components/image-icon";
import InfiniteScroll from "@/components/infinite-scroll";
import ProjectExhibition from "../project-exhibition";
import YztSp from "../yzt-sp";
import YztXcfc from "../live-image-x";

const TypicalStylePage = () => {
  const tabs = [
    {
      label: "学雷锋先进典型",
      value: "dxfc_hy1",
    },
    {
      label: "志交会现场风采",
      value: "yzt-xcfc",
    },
    // {
    //   label: "志交会现场风采",
    //   value: "dxfc_sz",
    // },
    // {
    //   label: "地方品牌",
    //   value: "dxfc_hy",
    // },
   
    {
      label: "展厅展览",
      value: "yzt",
    },
  ];

  const [dictType, setDictType] = useState(tabs[0].value);
  const [projectTypelist, setProjectTypeList] = useState<
    Record<string, string>[]
  >([]);
  const [projectType, setProjectType] = useState("");
  const [currentPageNum, setCurrentPageNum] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [articleList, setArticleList] = useState<any[]>([]);

  const projectTypeLabel = useMemo(() => {
    return projectTypelist.find((item) => item.dictValue === projectType)
      ?.dictLabel;
  }, [projectType, projectTypelist]);

  useEffect(() => {
    if (dictType === "dxfc_hy1" || dictType === "yzt" || dictType === "yzt-xcfc") return;
      ajax
        .get("/dict/getByDictType", {
          params: {
            dictType,
          },
        })
        .then((res) => {
          setProjectTypeList(res.data);
          onProjectTypeChange(res.data[0].dictValue);
        });
  }, [dictType]);

  const onDictTypeChange = (val: string) => {
    setDictType(val);
    setProjectType("");
    setProjectTypeList([]);
    setArticleList([]);
    setHasMore(true);
    setCurrentPageNum(0);
  };

  const onProjectTypeChange = (val: string) => {
    console.log(val)
    setProjectType(val);
    setArticleList([]);
    setHasMore(true);
    setCurrentPageNum(0);
  };

  const loadMore = async () => {
    const pageNum = currentPageNum + 1;
    const { data } = await ajax.get("/typicalArticle/list", {
      params: {
        type: dictType,
        projectType,
        pageNum,
        pageSize: 10,
      },
    });
    setCurrentPageNum(data.pageNum);
    setArticleList((val) => [...val, ...(data.rows as any[])]);
    setHasMore(data.pageTotal > pageNum);
  };

  const navigate = useNavigate();

  const goToDetail = (id: string) => {
    navigate(`/article-detail/${id}`);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>云展厅</title>
      </Helmet>
      
      <Image className="top-banner" src={getImageUrl('bg_online_live','jpg')}  fit='fill'/>
    
      <div className="typical-style-page">
        <div className="typical-style-page-tabs">
          {tabs.map((item) => {
            return (
             
              <div
                key={item.value}
                onClick={() => onDictTypeChange(item.value)}
                className={classNames({
                  "typical-style-page-tabs-item": true,
                  active: item.value === dictType,
                })}
              >
                {item.label}
              </div>
            );
          })}
        </div>
        <div className="typical-style-page-container">
          {/* <If condition={dictType !== "dxfc_hy1" && dictType !== "yzt"}>
            <JumboTabs
              activeKey={projectType}
              onChange={(val) => onProjectTypeChange(val)}
              className="typical-style-page-Jumbotabs"
            >
              {projectTypelist.map((item) => (
                <JumboTabs.Tab key={item.dictValue} title={null} description={item.dictLabel} />
              ))}
            </JumboTabs>
          </If> */}

          <div className="typical-style-page-article-container">
            {/* <If condition={dictType !== "dxfc_hy1" && dictType !== "yzt"}>
              
              <div className="typical-style-page-article-list">
                {articleList.map((item) => (
                  <div
                    key={item.id}
                    className="typical-style-page-article-item"
                    onClick={() => goToDetail(item.id)}
                  >
                    <div className="typical-style-page-article-item-cover">
                      <ImageBox src={item.coverFilePath} fit="fill" />
                    </div>
                    <div className="typical-style-page-article-item-content">
                      <div className="typical-style-page-article-item-title">
                        {item.title}
                      </div>
                      <div className="typical-style-page-article-item-footer">
                      <div className="time">
                          {item.updateTime?item.updateTime:item.createTime}
                      </div> 
                      <div className="star">
                      <Image src={getImageUrl('smile')}  fit='fill'/>                         
                          {item.pageView}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {projectType && (
                  <InfiniteScroll
                    loadMore={loadMore}
                    hasMore={hasMore}
                    hasData={!!articleList.length}
                  />
                )}
              </div>
            </If> */}
            <If condition={dictType === "dxfc_hy1"}>
              <ProjectExhibition />
            </If>
            <If condition={dictType === "yzt-xcfc"}>
              <YztXcfc />
            </If>
            <If condition={dictType === "yzt"}>
              <YztSp />
            </If>
          </div>
        </div>
      </div>
    </>
  );
};

export default TypicalStylePage;

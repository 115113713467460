import {
  Avatar,
  PullToRefresh,
  JumboTabs,
  SearchBar,
  Tabs,
  Popup,
} from 'antd-mobile'
import React, { useEffect, useState } from 'react'
import { getSvg } from '@/utils/index'
import './index.less'
import { Helmet } from 'react-helmet'
import PickerInput from '@/components/picker-input';
import ajax from 'ajax'
import ImageIcon from '@/components/image-icon/index'
import ImageBox from '@/components/image-box/index'
import { useNavigate } from 'react-router-dom'
import InfiniteScroll from '@/components/infinite-scroll';

function Index() {
  const navigate = useNavigate()
  const [isReady, setIsReady] = useState(false)
  const [projectType, setProjectType] = useState('');
  const [projectTypeList, setProjectTypeList] = useState<any[]>([])
  const [currentPageNum, setCurrentPageNum] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [listData, setListData] = useState<any[]>([]);
  const [sortType, setSortType] = useState('');
  const [sortTypeOptions] = useState([[
    {
      label: '默认排序',
      value: '',
    },
    {
      label: '点赞量',
      value: 'give_Like desc',
    }
  ]]);

  const [city, setCity] = useState('');
  const [cityList, setCityList] = useState<any[]>([])
  const [cityPopupVisible, setCityPopupVisible] = useState(false);
  const [keyword, setKeyWord] = useState('')
  useEffect(() => {
    ajax.get('/dict/getByDictType', {
      params: {
        dictType: 'project_show_type'
      }
    }).then(res => {
      const list = [
        // {
        //   dictLabel: '全部',
        //   dictValue: '',
        // },
        ...res.data
      ]
      setProjectTypeList([list.map((item: any) => {
        return {
          label: item.dictLabel,
          value: item.dictValue
        }
      })]);
      // setProjectType(list[0].dictValue)
      onProjectTypeChange(list[0].dictValue);
    })

    ajax.get('/dict/getByDictType', {
      params: {
        dictType: 'project_show_city'
      }
    }).then((res) => {
      setCityList([
        {
          dictLabel: "全部",
          dictValue: "",
        },
        ...res.data
      ])
    })
  }, [])

  const onProjectTypeChange = (val: string) => {
    console.log(val)
    setProjectType(val);
    setListData([]);
    setHasMore(true);
    setCurrentPageNum(0);
  };

  const loadMore = async (pageNum = currentPageNum + 1) => {
    const { data } = await ajax.get("/article/projectShowList", {
      params: {
        keyword,
        projectType,
        sortType,
        city,
        pageNum,
        pageSize: 10,
      },
    });
    setCurrentPageNum(data.pageNum);
    setListData((val) => [...val, ...(data.rows as any[])]);
    setHasMore(data.pageTotal > pageNum);
  };

  // useEffect(() => {
  //   if(isReady){
  //     onRefresh()
  //   }else{
  //     setIsReady(true)
  //   }
  // }, [projectType, sortType, city])

  const onRefresh = async () => {
    // setHasMore(true);
    setCurrentPageNum(0);
    setListData([])
    await loadMore();
  }

  const goArticleDetail = (id: string) => {
    navigate(`/article-detail/${id}`)
  }


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>先进典型</title>
      </Helmet>
      <div className="project-exhibition-epage">
        <PullToRefresh onRefresh={onRefresh}>
          <JumboTabs
            activeKey={projectType}
            onChange={(val) => onProjectTypeChange(val)}
          >
            {projectTypeList.flat().map((item, index) => {
              return (
                <JumboTabs.Tab
                  key={item.value}
                  title={null}
                  description={item.label}
                />
              );
            })}
          </JumboTabs>
          <div className="project-exhibition-epage-header">
            {/* <SearchBar value={keyword} onChange={(val) => setKeyWord(val)} placeholder='请输入内容' onSearch={() => onRefresh ()} /> */}
            {/* <div className='project-exhibition-page-city-tabs'>
              <Tabs activeKey={city} onChange={setCity}>
                {
                  cityList.map(item => {
                    return <Tabs.Tab key={item.dictValue} title={item.dictLabel} />
                  })
                }
              </Tabs>
              <ImageIcon name="menu" onClick={() => setCityPopupVisible(true)} />
              <Popup
                visible={cityPopupVisible}
                onMaskClick={() => setCityPopupVisible(false)}
                position='top'
                className='project-exhibition-page-city-popup'
              >
                {
                  cityList.map(item => {
                    return <div key={item.dictValue} className='project-exhibition-page-city-popup-item'>
                      <div
                        className={classNames({ active: city === item.dictValue })}
                        onClick={() => {
                          setCity(item.dictValue)
                          setCityPopupVisible(false)
                        }}
                      >{item.dictLabel}</div>
                    </div>
                  })
                }
              </Popup>
            </div> */}
          </div>
          <div className="project-exhibition-epage-list">
            {listData.map((item) => {
              return (
                <div className="project-exhibition-epage-item" key={item.id}>
                  <div
                    className="project-exhibition-epage-project"
                    onClick={() => goArticleDetail(item.id)}
                  >
                    <div className="project-exhibition-epage-item-cover">
                      <ImageBox src={item.coverFilePath} fit="fill" />
                      <div className="project-exhibition-epage-item-project-type">
                        {item.projectType}
                      </div>
                    </div>
                    <div className="project-exhibition-epage-item-title">
                      {item.title}
                    </div>
                    <div className="project-exhibition-epage-item-footer">
                      <div>
                        <Avatar
                          style={{ "--size": "16px" }}
                          src={item.regionPicPath}
                        />
                        {item.city}
                      </div>
                      <div>
                        <Avatar
                          style={{ "--size": "16px" }}
                          src={getSvg("tab1-like", "svg")}
                        />
                        {item.pageView}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <InfiniteScroll
              loadMore={() => loadMore()}
              hasMore={hasMore}
              hasData={!!listData.length}
            />
          </div>
        </PullToRefresh>
      </div>
    </>
  );
}
export default React.memo(Index)
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import './index.less'
import ajax from 'ajax'
import StarBox from "@/components/star-box";
import { Avatar } from "antd-mobile";
import { getSvg } from '@/utils'

function index() {
  const { id = '' } = useParams()

  const [data, setData] = useState({
    content: '',
    source: '',
    giveLike: '0',
    title: '',
    city: '',
    projectType: '',
    deptName: '',
    updateTime: '',
  });

  useEffect(() => {
    ajax.get(`/article/${id}`).then((res) => {
      setData(res.data)
    })
  }, [])

  const onclic = async () => {
    const res = await ajax.post('/article/updateGiveLike', { id });
    setData((data) => ({
      ...data,
      giveLike: res.data.giveLike
    }))
  }

  return (
    <div className="rk-file">
      {/* <div className="header">
        <div className="title">{data.title}</div> */}
        {/* <If condition={!!data.city}>
          <div className="detail">
            <span>所属地区：{data.city}</span>
          </div>
        </If>
        <If condition={!!data.projectType}>
          <div className="detail">
          <span>项目类型：{data.projectType}</span>
          </div>
        </If>
        <If condition={!!data.source}>
          <div className="detail">
          <span>实施单位：{data.source}</span>
          </div>
        </If>
        
        <If condition={!!data.deptName}>
          <div className="detail">
            <Avatar style={{ '--size': '16px' }} src={getSvg('flag', 'svg')} />
            <span>来源：{data.deptName}</span>
          </div>
        </If> */}
        {/* <If condition={!!data.updateTime.slice(0,16)}>
          <div className="detail">
            <span>{data.updateTime.slice(0,16)}</span>
          </div>
        </If> */}
        {/* <StarBox onClick={onclic} content={
          props => (
            <div className="like" { ... props}>
              <i className="iconfont icon-dianzan"></i>
              <span>{data.giveLike}</span>
            </div>
          )
        } /> */}
      {/* </div> */}
      {/* <div className="gap"></div> */}
      <div className="article">
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data.content }}
        ></div>
      </div>
    </div>
  )
}
export default observer(index)

import React, { useRef, ReactNode } from 'react';
// @ts-ignore
import mojs from '@mojs/core';


type StarBoxProps = {
  onClick: () => Promise<void>;
  content: (data: any) => ReactNode | any
}

const StarBox = (props: StarBoxProps) => {
  const boxRef = useRef();
  const hearted = useRef(false);
  const onClick = async() => {
    if(hearted.current){
      return;
    }
    hearted.current = true;
    await props.onClick();
    hearted.current = false;

    const aperture = new mojs.Transit({
      parent: boxRef.current,
      duration: 750,
      type: 'circle',
      radius: { 0: 20 },
      fill: 'transparent',
      stroke: '#E05B5B',
      strokeWidth: { 20: 0 },
      opacity: 0.6,
      isRunLess: true,
      easing: mojs.easing.bezier(0, 1, 0.5, 1),
      onComplete () {
        this.el.remove()
      }
    })

    const burst = new mojs.Burst({
      radius: { 0: 50 },
      parent: boxRef.current,
      easing: mojs.easing.bezier(0.1, 1, 0.3, 1),
      duration: 1500,
      delay: 300,
      children: {
        duration: 750,
        radius: { 0: 'rand(5, 25)' },
        shape: ['circle', 'rect', 'polygon'],
        fill: ['#1abc9c', '#2ecc71', '#00cec9', '#3498db', '#9b59b6', '#fdcb6e', '#f1c40f', '#e67e22', '#e74c3c', '#e84393'],
        degreeShift: 'rand(-90, 90)',
        delay: 'stagger(0, 40)',
      },
      opacity: 0.6,
      count: 10,
      onComplete () {
        this.el.remove()
      },
    });
    const bounce = new mojs.Tween({
      duration: 1200,
    })

    new mojs.Timeline().add(burst, aperture, bounce).play();
  }

  return (
    <>
      {props.content({ ref: boxRef, onClick, })}
    </>
  )
}

export default React.memo(StarBox)
import React from 'react'
import { InfiniteScroll, DotLoading } from 'antd-mobile'
import ImageIcon from '../image-icon';
import './index.less';

type InfiniteScrollProps = {
  loadMore: any;
  hasMore: boolean;
  hasData: boolean;
}

const Scroll = ({ loadMore, hasMore, hasData }: InfiniteScrollProps) => {

  if (!hasData && !hasMore) {
    return <div className='infinite-scroll-emtpy-box'>
      <ImageIcon name='empty' />
      <div className='infinite-scroll-emtpy-desc'>
        暂时没有数据哦~
      </div>
    </div>
  }

  return (
    <InfiniteScroll loadMore={loadMore} hasMore={hasMore} className="infinite-scroll">
      <>
        {hasMore ? (
          <>
            <span>Loading</span>
            <DotLoading />
          </>
        ) : (
          <span>没有更多了~</span>
        )}
      </>
    </InfiniteScroll>
  )
}

export default React.memo(Scroll)
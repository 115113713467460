import { makeObservable, observable, action, runInAction } from "mobx";
import ajax from 'ajax'
import { ArticleProps } from 'types'
class Store {

  constructor() {
    makeObservable(this, {

      list: observable,
      type:observable,
      init: action,
      getList: action,
      clear: action

    });
  }




  type:string = ''
  list: Array<ArticleProps> = []

  init = (type:string)=>{
    this.type = type
    this.getList()
  }

  getList = () => {

    ajax.get('/article/guideList', {
      params: {
        pageNum: 1,
        pageSize: 10,
        isPublish: 1,
        projectType: this.type
      }
    }).then((res) => {
      runInAction(() => {
        this.list = res.data.rows
      })


    })


  }

  clear = ()=>{
    this.list = []
  }




}
export default new Store();

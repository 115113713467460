import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './index.less'
import xgPlayer from "xgplayer";
import hlsjsPlayer from 'xgplayer-hls.js'
import ajax from "ajax";

interface Props {
  url: string,
  poster: string
}

let uuid = 0;

// function Video({ url, poster }: Props) {
function Video() {

  const [videoUrl, setVideoUrl] = useState("");
  const [videoPoster, setVideoPoster] = useState("");
  const [videoId] = useState(uuid++);
  const { id = ''} = useParams();

  const [play6videos] = useState([
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play6/pic/0.png',
      value: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play6/情景剧暖流.mp4',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play6/pic/1.png',
      value: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play6/奖项发布.mp4',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play6/pic/2.png',
      value: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play6/小合唱.mp4',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play6/pic/3.png',
      value: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play6/情景剧山爸山妈.mp4',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play6/pic/4.png',
      value: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play6/扬州快板.mp4',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play6/pic/5.png',
      value: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play6/杂技.mp4',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play6/pic/6.png',
      value: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play6/讲述磨刀老人.mp4',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play6/pic/7.png',
      value: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play6/速速带货.mp4',
    },
    {
      label: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play6/pic/8.png',
      value: 'https://vol-nt-mini-apps-01.ehuigu.com/static/videoOnline/play6/音诗画《我们的名字叫雷锋》.mp4',
    },
  ]);

  const startPlayer = (url: string,poster: string) => {
    
    const player = new xgPlayer({
      id: `xg-video-live-${videoId}`,
      url:url,
      poster:poster,
      width: '100%',
      height: '100vh',
      cssFullscreen: true,
      // controls: false,
      loop: true,
      videoInit: true,
      'x5-video-player-type':'h5',
      playsinline: true,
      lang:"zh-cn",
      autoplay: true,
      // videoInit: true,
      // cssFullscreen: true,
      // fluid: true
    })
    // player.getFullscreen(player.root);
    

    // player.getFullscreen(player.root);
    // player.play()  
    return () => {
      player.destroy();
      // alert("xxx")
    }
  }


  useEffect(() => {
    
      setVideoUrl(play6videos[parseInt(id)].value)
      setVideoPoster(play6videos[parseInt(id)].label)
    
    startPlayer(videoUrl,videoPoster)
  });

  

  return (
    <div className="xg-video" id={`xg-video-live-${videoId}`}>
    </div>
  )
}
export default observer(Video)

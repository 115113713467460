import { useEffect, useState, useMemo } from 'react'
import './App.less'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { FloatingBubble, TabBar } from 'antd-mobile'
import { tabs } from './routes/index'
import { observer } from 'mobx-react'
import { getImageUrl } from './utils'
import ImageIcon from './components/image-icon'
import ajax from 'ajax'
import wx from 'weixin-js-sdk-ts'

function App() {
  const arr = [
    '/home',
    '/project-dtfb',
    '/typical-style',
    '/live',
    '/characteristic-city',
    '/civilized-practice',
  ]
  const navigate = useNavigate()
  const location = useLocation()
  const [activeKey, setActiveKey] = useState('home')


  const onChange = (key: string) => {
    setActiveKey(key)
    navigate(key)
  }
  const back = () => {
    navigate('home')
    setActiveKey('')
  }

  useEffect(() => {
    const url = window.location.href.split('#')[0];

    ajax.get('/wechat/signature', {
      params: {
        url: url
      }
    }).then((res) => {
    
      const { appid, timestamp, nonceStr, signature } = res.data;

      wx.config({
        debug: false,
        appId: appid,
        timestamp: timestamp,
        nonceStr: nonceStr,
        signature: signature,
        jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage'],
        openTagList: []
      });
      wx.ready(function () {
        wx.onMenuShareAppMessage({
          title: '第七届江苏志愿服务展示交流会', // 分享标题
          desc: '学习雷锋楷模 弘扬志愿精神 活动时间：2023年3月2日-3月4日', // 分享描述
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: getImageUrl('logo', 'png'), // 分享图标
          type: 'link', // 分享类型,music、video或link，不填默认为link
          success: function () {
            console.log('ok')
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          }
        })

        //分享到朋友圈
        wx.onMenuShareTimeline({
          title: '第七届江苏志愿服务展示交流会', // 分享标题
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: getImageUrl('logo', 'png'), // 分享图标
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          }
        })
      });

    })


  }, [])


  useEffect(() => {
    if (location.pathname == '/') {
      navigate('index')
    }
  }, [])

  useEffect(() => {
    setActiveKey(location.pathname.split('/')[1])
  }, [location])

  const goLiveImage = () => {
    navigate('live-image')
  }

  const visibleHomeIcon = useMemo(() => {
    return !location.pathname.includes('/home') && !location.pathname.includes('/index')
  }, [location.pathname])

  const isLivePage = useMemo(() => {
    return location.pathname === '/live'
  }, [location.pathname])

  return (
    <div className="app">
      <Outlet />
      <If condition={arr.includes(location.pathname)}>
        <div className="tabbar">
          <TabBar safeArea activeKey={activeKey} onChange={onChange}>
            {tabs.map(item => (
              <TabBar.Item key={item.key} icon={item.icon} title={item.title} style={{}} />
            ))}
          </TabBar>
        </div>
      </If>
      <If condition={visibleHomeIcon}>
        <FloatingBubble
          style={{
            '--initial-position-right': '10px',
            '--initial-position-bottom': '120px',
            '--edge-distance': '10px',
          }}
        >
          <If condition={isLivePage}>
            <ImageIcon name="live-image-btn" onClick={goLiveImage} />
          </If>

          <ImageIcon name="logo-btn" onClick={back} />
        </FloatingBubble>
      </If>
    </div>
  )
}

export default observer(App)
import { observer } from 'mobx-react'
import './index.less'
import {Image } from 'antd-mobile' 
import { getImageUrl } from '@/utils'
import { Helmet } from 'react-helmet'

const HomePage = () => {

// function Index() {
  return (
    <div className="home-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>第七届江苏志愿服务展示交流会</title>
      </Helmet>
      <Image src={getImageUrl('home-banner','jpg')}  fit='fill'/>
    </div>
  )
}
// export default observer(Index)
export default HomePage
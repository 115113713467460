import { Image } from "antd-mobile";
import { Helmet } from "react-helmet";
import ajax from "ajax";
import "./index.less";
import { useEffect, useState } from "react";
import { ImageViewer, PullToRefresh } from "antd-mobile";
import { getImageUrl } from "@/utils";
import classNames from 'classNames';
import InfiniteScroll from '@/components/infinite-scroll';

const LiveImagePage = () => {
  const [currentPageNum, setCurrentPageNum] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [listData, setListData] = useState<any[]>([]);
  const [hasInit, setHasInit] = useState(false)
  const [orderByOptoins] = useState([
    {
      label: '图片展播',
      value: 'sort desc, sort asc, id asc',
    },
    {
      label: '热门',
      value: 'give_Like desc ',
    },
  ]);

  const [orderBy, setOrderBy] = useState(orderByOptoins[0].value);
  const [likeNum, setLikeNum] = useState(0);


  const loadMore = async (pageNum = currentPageNum + 1) => {
    const { data } = await ajax.get("/exhibition/page", {
      params: {
        orderBy,
        pageNum,
        pageSize: 10,
        type:1,
      },
    });
    setCurrentPageNum(data.pageNum);
    if (pageNum === 1) {
      setListData(data.rows);
    } else {
      setListData((val) => [...val, ...(data.rows as any[])]);
    }
    setHasMore(data.pageTotal > pageNum);
  };

  const getSumLike = () => {
    ajax
      .get("/exhibition/sumLike", {
        
      })
      .then((res) => {
        setLikeNum(res.data)
      });
  }

  // const getSumLike = () => {
  //   // const data  = ajax.get("/exhibition/sumLike");
  //   // // alert(data.data)
  //   // console.info("xx",data)
  //   // // setLikeNum(data.data)
  // }

  const updatePageView = (id: string) => {
    ajax.post("/exhibition/updatePageView", { id });
  }

  const previeImage = (defaultIndex: number, id: string) => {
    updatePageView(id)

    ImageViewer.Multi.show({
      defaultIndex,
      images: listData.map((item) => item.filePath),
      onIndexChange: (index) => {
        updatePageView(listData[index].id)
      }
    });
  };

  const [bannerUrl] = useState(getImageUrl('bg_online_live','jpg'))


  const onRefresh = async () => {
    await loadMore(1);
  }


  const onOrderByChange = (val: string) => {
    if(val !== orderBy){
      setOrderBy(val);
    }
  }

  useEffect(() => {
    if(hasInit){
      onRefresh();
    }else {
      setHasInit(true)
    }
    // updatePageView(0)
  }, [orderBy])

  useEffect(() => {
    getSumLike()
  })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>图片直播</title>
      </Helmet>
      <div className="live-image-page">
        <PullToRefresh onRefresh={onRefresh}>
          
          {/* <Image className="live-image-page-banner" src={bannerUrl} /> */}
          
          <Image className="top-banner" src={bannerUrl}  fit='fill'/>
          <div className="live-image-page-desc">
            <div className="live-image-page-title">
              第七届江苏志愿服务交流展示会
            </div>
            <div className="live-image-page-detail">
              已有<span>{likeNum}</span>人次浏览
            </div>
            <div className="live-image-page-location">
              <div className="star">
                  <Image src={getImageUrl('time')}  fit='fill'/>
              </div>
              2023.03.02-2023.03.04
            </div>
            <div className="live-image-page-location">
              <div className="star">
                  <Image src={getImageUrl('location')}  fit='fill'/>
              </div>
              江苏·南通
            </div>
          </div>
          <div className="live-image-page-tabs">
            {
              orderByOptoins.map(item => {
                return (
                  <div
                    key={item.value}
                    className={classNames({ 'live-image-page-tabs-item': true, active: item.value === orderBy })}
                    onClick={() => onOrderByChange(item.value)}
                  >
                    {item.label}
                  </div>
                )
              })
            }
          </div>
          <div className="live-image-page-list">
            {listData.map((item, idx) => {
              return (
                <div className="live-image-page-item" key={item.id} onClick={() => previeImage(idx, item.id)}>
                  <Image src={item.filePath} fit="cover" />
                </div>
              );
            })}
          </div>
          <InfiniteScroll loadMore={() => loadMore()} hasMore={hasMore} hasData={!!listData.length} />
        </PullToRefresh>
      </div>
    </>
  );
};

export default LiveImagePage;

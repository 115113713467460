import {tabs} from  '../routes/index'
export function getImageUrl(name:string,ext = 'png') {
  return new URL(`/src/assets/imgs/${name}.${ext}`, import.meta.url).href
}
export function getSvg(name:string,ext = 'png') {
  return new URL(`/src/assets/svg/${name}.${ext}`, import.meta.url).href
}

export function getTitle(key:string){
  let res = ''
  tabs.forEach((item)=>{
    if(key.includes(item.key)) {
      res = item.title
    }
  })

  return res
}

export const getAssestUrl = (url: string) => {
  return new URL(`/src/assets/${url}`, import.meta.url).href
}


export const flexible = () => {
  function resize() {
    let fontsize = document.documentElement.clientWidth / 10
    fontsize = Math.min(75, fontsize)
    document.documentElement.style.fontSize = fontsize + 'px'
  }
  window.addEventListener('load', resize)
  window.addEventListener('resize', resize)
}
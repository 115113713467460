import './index.less'
import {Image } from 'antd-mobile' 
import { getImageUrl } from '@/utils'
import { Helmet } from 'react-helmet'

const HdlcIndexPage = () => {

// function Index() {
  return (
    <div className="home-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>活动流程详情</title>
      </Helmet>
      <Image src={getImageUrl('hdlcDetail')}  fit='fill'/>
    </div>
  )
}
// export default observer(Index)
export default HdlcIndexPage
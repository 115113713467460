import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './assets/icons/iconfont/iconfont.css'
import {BrowserRouter} from 'react-router-dom'
import Menus from './routes/index'
// @ts-ignore
import TouchEmulator from 'hammer-touchemulator';
import { flexible } from './utils'
flexible();
TouchEmulator()
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <BrowserRouter>
    <Menus />
  </BrowserRouter>
)

import { useEffect } from 'react'
import { Image } from 'antd-mobile'
import './index.less'
import { useNavigate, useParams } from 'react-router-dom'
import store from './index.store'
import { observer } from 'mobx-react'
function index() {
  const { id = '' } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    store.init(id)
  }, [])
  return (
    <div className="com-page">
      <div className="card">
        <div className="card-top">
          <div className="avatar">
            <Image
              width={48}
              height={48}
              src={store.data.avatar}
              fit="cover"
              style={{ borderRadius: 23 }}
            />
            <div className="vip"></div>
          </div>
          <div className="top">
            <div className="name">{store.data.nickName}</div>
            <div className="dep">
              <span className="text1">来自 </span>
              <span className="text2">{store.data.deptName} </span>
            </div>
          </div>
        </div>
        <div className="center">
          <div className="left">
            <div>
              {store.data.createTime || ''} · 浏览{store.data.clickQuantity}次{' '}
            </div>
          </div>
          <div className="right"></div>
        </div>
        <div className="card-content">{store.data.content}</div>
        <div className="img">
          {store.data.files?.map(file => {
            return (
              <Image
                key={file.id}
                className="img-store.data"
                src={file.filePath}
                fit="cover"
              ></Image>
            )
          })}
        </div>
        <div className="icons">
          <div className="box">
            <i className="iconfont icon-31xiaoxi"></i>
            <span>评论</span>
          </div>
          <div className="box">
            <i className="iconfont icon-dianzan"></i>
            <span>{store.data.thumbsUpQuantity}</span>
          </div>
          <div className="box" onClick={() => navigate(-1)}>
            <span>完整报道</span>
            <i className="iconfont icon-caret-right"></i>
          </div>
        </div>
        <div className="like">
          <i className="iconfont icon-dianzan"></i>
          <span>{store.data.thumbsUpQuantity}人赞过</span>
        </div>
      </div>
      <div>
      </div>
    </div>
  )
}
export default observer(index)

import './index.less'
import { getImageUrl } from '@/utils'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import ImageBox from '@/components/image-box/index'

function index() {
  const navigate = useNavigate()

  const onClick = (type: string) => {
    if (type === 'samplingPoint') {
      window.location.href = 'https://hsyy.nantong.cn'

      return
    } else if(type === '活动流程') {
      navigate(`/hdlcIndex`)
    } else {
      navigate(`/civilized-practice/${type}`)
    }
  }

  const list = [
    [
      {
        type: "酒店住宿",
        url: "jdzs",
      },
      // {
      //   type: "活动预告",
      //   url: "game-preview",
      // },
      {
        type: "活动流程",
        url: "hdlc",
      },
      // {
      //   type: "景点",
      //   url: "attractions",
      // },
      // {
      //   type: "美食推荐",
      //   url: "food-recommendation",
      // },
      {
        type: "公益市集",
        url: "gyjs",
      },
      
    ],
    [
      {
        type: "乘车指南",
        url: "bus-line",
      },

      {
        type: "服务联系",
        url: "fwlx",
      },
      
      // {
      //   type: "samplingPoint",
      //   url: "sampling-point",
      // },
    ]
  ]

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>服务指南</title>
      </Helmet>
      <div className="civilized-practice-page">
        {list.map((group, idx) => {
          return (
            <div key={idx} className='civilized-practice-page-group'>
              {
                group.map(item => {
                  return (
                    <div key={item.type} className='civilized-practice-page-item'>
                      <ImageBox src={getImageUrl(item.url)} onClick={() => onClick(item.type)} />
                    </div>
                  )
                })
              }
            </div>
          )
        })}
      </div>
    </>
  )
}
export default observer(index)

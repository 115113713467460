import { Image } from "antd-mobile";
import { Helmet } from "react-helmet";
import ajax from "ajax";
import "./index.less";
import { useEffect, useState } from "react";
import { ImageViewer, PullToRefresh } from "antd-mobile";
import { getImageUrl } from "@/utils";
import InfiniteScroll from '@/components/infinite-scroll';

const LiveImagePage2 = () => {
  const [currentPageNum, setCurrentPageNum] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [listData, setListData] = useState<any[]>([]);
  const [hasInit, setHasInit] = useState(false)

  const loadMore = async (pageNum = currentPageNum + 1) => {
    const { data } = await ajax.get("/exhibition/page", {
      params: {
        orderBy:"sort desc, sort asc, id asc",
        pageNum,
        pageSize: 10,
        type:1,
      },
    });
    setCurrentPageNum(data.pageNum);
    setHasMore(data.pageTotal > pageNum);
    setListData((val) => [...val, ...(data.rows as any[])]);
  };

  const updatePageView = (id: string) => {
    ajax.post("/exhibition/updatePageView", { id });
  }

  const previeImage = (defaultIndex: number, id: string) => {
    updatePageView(id)

    ImageViewer.Multi.show({
      defaultIndex,
      images: listData.map((item) => item.filePath),
      onIndexChange: (index) => {
        updatePageView(listData[index].id)
      }
    });
  };

  const [bannerUrl] = useState(getImageUrl("dtfb",'jpg'))


  const onRefresh = async () => {
    setCurrentPageNum(0);
    setListData([])
    await loadMore();
  }

  // useEffect(() => {
    
  //   if(!hasInit){
  //     onRefresh();
  //   }else {
  //     setHasInit(true)
  //   }
  // }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>动态发布</title>
      </Helmet>
      <div className="live-image-page">
        <Image className="top-banner" src={bannerUrl}  fit='fill'/>
        <PullToRefresh onRefresh={onRefresh}>
          <div className="live-image-page-list">
            {listData.map((item, idx) => {
              return (
                <div className="live-image-page-item" key={item.id} onClick={() => previeImage(idx, item.id)}>
                  <Image src={item.filePath} fit="cover" />
                </div>
              );
            })}
          </div>
          <InfiniteScroll loadMore={() => loadMore()} hasMore={hasMore} hasData={!!listData.length} />
        </PullToRefresh>
      </div>
    </>
  );
};

export default LiveImagePage2;

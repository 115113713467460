import { observer } from "mobx-react";
import { useState } from "react";
import { PullToRefresh } from "antd-mobile";
import "./index.less";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import ImageBox from "@/components/image-box/index";
import ajax from "ajax";
import InfiniteScroll from "@/components/infinite-scroll";
import XgVideo from "@/components/xg-video/index";
import { getAssestUrl, getImageUrl } from "@/utils";
import xgPlayer from "xgplayer";
import hlsjsPlayer from 'xgplayer-hls.js'



function Index() {
  const navigate = useNavigate();
  const [currentPageNum, setCurrentPageNum] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [listData, setListData] = useState<any[]>([]);
  const firstVideData = listData[0];
  const rangeVideoData = listData.slice(1);
  const loadMore = async (pageNum = currentPageNum + 1) => {
    const { data } = await ajax.get("/live/list", {
      params: {
        pageNum,
        pageSize: 10,
      },
    });
    setCurrentPageNum(data.pageNum);
    if (pageNum === 1) {
      setListData(data.rows);
    } else {
      setListData((val) => [...val, ...(data.rows as any[])]);
    }
    setHasMore(data.pageTotal > pageNum);
  };

  const [bannerUrl] = useState(getImageUrl("bg_online_live"));

  const onRefresh = async () => {
    await loadMore(1);
  };

  const getTitlePre = (liveState: number): string => {
    let res = "";
    switch (liveState) {
      case 0: {
        res = "未开始";
        break;
      }
      case 1: {
        res = "直播中";
        break;
      }
      case 2: {
        res = "暂停";
        break;
      }
      case 3: {
        res = "回播";
        break;
      }
      case 4: {
        res = "完成";
        break;
      }
    }

    return res;
  };

  const goToDetail = (id: string) => {
    navigate(`/live-detail/${id}`);
  };

  const player = new hlsjsPlayer({
    id: "videoBox",
    // url: "//sf1-cdn-tos.huoshanstatic.com/obj/media-fe/xgplayer_doc_video/hls/xgplayer-demo.m3u8",
    // url: "http://vol-nt-admin-01.huigu.com/fvod/Volu/ObHixXhnR/video.m3u8",
    url:getAssestUrl("videos/cover.mp4"),
    // url: getAssestUrl("videos/cover.mp4"),
    width: "100%",
    height: "30vh",
    // cssFullscreen: true,
    videoInit: true,
    controls: false,
    loop: true,
    'x5-video-player-type':'h5',
    playsinline: true,
    lang:"zh-cn",
    poster: getImageUrl("bg_online_live", "jpg"),
  //   thumbnail: {
  //     // pic_num: 44,
  //     width: 160,
  //     height: 90,
  //     col: 10,
  //     row: 10,
  //     urls: ['./xgplayer-demo-thumbnail-1.jpg','./xgplayer-demo-thumbnail-2.jpg'],
  //     isShowCoverPreview: false
  // },
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>线上直播</title>
      </Helmet>
      <div className="live-online-page">
        <PullToRefresh onRefresh={onRefresh}>
          <div className="live-online-page-banner top-banner">
            {/* <XgVideo
              url={getAssestUrl("videos/cover.mp4")}
              poster={getImageUrl("bg_online_live", "png")}
            /> */}
            <div id="videoBox"></div>
            
          </div>
          <div className="live-online-page-title">
          <ImageBox src={getImageUrl('rmzb')}  fit='cover'/>
          </div>

          <div className="live-online-page-list">
          {listData.map((item, idx) => {
                return (
                  <div
                    className="live-online-page-item"
                    key={item.id}
                    onClick={() => goToDetail(item.id)}
                  >
                    <ImageBox
                      width={"100%"}
                      fit="fill"
                      src={item.showPreviewImagePath}
                    />
                    <div className="live-online-page-item-title">
                      {item.title}
                      <span className="live-online-page-item-title-time">
                        {item.liveStartTime.slice(0,16) }-{item.liveEndTime.slice(11,16)}
                      </span>
                      <span className="live-online-page-item-title-time">
                        {item.clickQuantity} 观看
                      </span>
                    </div>
                    <div
                      className={`live-online-page-item-status live-online-page-item-status-${item.liveState}`}
                    >
                      {getTitlePre(item.liveState)}
                    </div>
                  </div>
                );
              })}
            {/* <div
              className="live-online-page-item"
              key={firstVideData?.id}
              onClick={() => goToDetail(firstVideData?.id)}
            >
              <ImageBox
                width={"100%"}
                fit="cover"
                src={firstVideData?.showPreviewImagePath}
              />
              <div className="live-online-page-item-title">
                {firstVideData?.title}
                <span className="live-online-page-item-title-time">
                  {firstVideData?.liveStartTime}
                </span>
                <span className="live-online-page-item-title-time">
                  {firstVideData?.clickQuantity} 观看
                </span>
              </div>
              <div
                className={`live-online-page-item-status live-online-page-item-status-${firstVideData?.liveState}`}
              >
                {getTitlePre(firstVideData?.liveState)}
              </div>
            </div> */}
            {/* <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap'}} >
              {rangeVideoData.map((item, idx) => {
                return (
                  <div
                    className="live-online-page-ritem"
                    key={item.id}
                    onClick={() => goToDetail(item.id)}
                  >
                    <ImageBox
                      width={"100%"}
                      fit="fill"
                      src={item.showPreviewImagePath}
                    />
                    <div className="live-online-page-ritem-title">
                      {item.title}
                      <span className="live-online-page-ritem-title-time">
                        {item.liveStartTime}
                      </span>
                      <span className="live-online-page-ritem-title-time">
                        {item.clickQuantity} 观看
                      </span>
                    </div>
                    <div
                      className={`live-online-page-ritem-status live-online-page-ritem-status-${item.liveState}`}
                    >
                      {getTitlePre(item.liveState)}
                    </div>
                  </div>
                );
              })}
            </div> */}
          </div>
          <InfiniteScroll
            loadMore={() => loadMore()}
            hasMore={hasMore}
            hasData={!!listData.length}
          />
        </PullToRefresh>
      </div>
    </>
  );
}
export default observer(Index);

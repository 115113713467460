import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './index.less'
import xgPlayer from "xgplayer";
import hlsjsPlayer from 'xgplayer-hls.js'
import ajax from "ajax";

interface Props {
  url: string,
  poster: string
}

let uuid = 0;

// function Video({ url, poster }: Props) {
function Video() {

  const [videoUrl, setVideoUrl] = useState("");
  const [videoPoster, setVideoPoster] = useState("");
  const [videoId] = useState(uuid++);
  const { id = ''} = useParams();

  const startPlayer = (url: string,poster: string) => {
    
    const player = new xgPlayer({
      id: `xg-video-live-${videoId}`,
      url:url,
      poster:poster,
      width: '100%',
      height: '100vh',
      cssFullscreen: true,
      // controls: false,
      loop: true,
      videoInit: true,
      'x5-video-player-type':'h5',
      playsinline: true,
      lang:"zh-cn",
      autoplay: true,
      // videoInit: true,
      // cssFullscreen: true,
      // fluid: true
    })
    // player.getFullscreen(player.root);
    

    // player.getFullscreen(player.root);
    // player.play()  
    return () => {
      player.destroy();
      // alert("xxx")
    }
  }


  useEffect(() => {
    
      ajax
        .get("/video/getById", {
          params: {
            id: id
          },
        })
        .then((res) => {
          console.info(res.data)
          if(res.data.onlineFilePath!=null&&res.data.onlineFilePath!=""){
            setVideoUrl(res.data.onlineFilePath)
          } else{
            setVideoUrl(res.data.filePath)
          }
          if(res.data.onlineImgPath!=null&&res.data.onlineImgPath!=""){
            setVideoPoster(res.data.onlineImgPath)
          } else{
            setVideoPoster(res.data.thumbnailPath)
          }
          
        });
    
        startPlayer(videoUrl,videoPoster)
  });

  

  return (
    <div className="xg-video" id={`xg-video-live-${videoId}`}>
    </div>
  )
}
export default observer(Video)
